import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import Link from './Link';

const ProcessNav = ({ show }) => {
  const navClass = clsx(
    'w-full h-full flex flex-col items-center justify-center lg:flex lg:w-2/3 lg:flex-row lg:justify-end',
    { hidden: !show }
  );

  return (
    <nav className={navClass}>
      <Link
        type="external"
        to="/"
        className="py-2 text-lg text-bluegrey lg:text-base lg:px-5 hover:text-primary"
      >
        Bemutatkozás
      </Link>
      <Link
        className="py-2 text-lg text-bluegrey lg:text-base lg:px-5 hover:text-primary"
        type="external"
        to="/#where"
        title="Hol tanulhatom?"
      >
        Hol tanulhatom?
      </Link>
      <Link
        type="external"
        activeStyle={{ color: '#0E1655' }}
        to="/folyamat"
        className="py-2 text-lg text-bluegrey lg:text-base lg:px-5 hover:text-primary"
      >
        A képzés menete
      </Link>
      <Link
        type="external"
        activeStyle={{ color: '#0E1655' }}
        to="/partner"
        className="py-2 text-lg text-bluegrey lg:text-base lg:px-5 hover:text-primary"
      >
        Partnerek ajánlatai
      </Link>
      <Link
        className="py-2 text-lg text-bluegrey lg:text-base lg:px-5 hover:text-primary"
        type="external"
        to="/#faq"
        title="Gyakori kérdések"
      >
        Gyakori kérdések
      </Link>
      <Link
        className="py-2 text-lg text-bluegrey lg:text-base lg:px-5 hover:text-primary"
        type="external"
        to="/#contact"
        title="Kapcsolat"
      >
        Kapcsolat
      </Link>
    </nav>
  );
};

ProcessNav.propTypes = {
  show: PropTypes.bool.isRequired
};

export default ProcessNav;
