import React from 'react';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';

const Card = ({ img, title, body }) => (
  <div className="w-full lg:w-5/6 bg-white p-4 rounded flex flex-col md:flex-row justify-center my-2 mx-auto">
    {img && (
      <div
        className="w-full h-full lg:w-4/12 rounded"
        style={{
          background:
            'linear-gradient(180deg, #F5F6F9 0%, rgba(245, 246, 249, 0.0001) 100%)'
        }}
      >
        <Img
          alt={title}
          fluid={img.childImageSharp.fluid}
          className="flex rounded items-center justify-center m-auto"
          style={{
            minWidth: 240,
            maxWidth: 320
          }}
        />
      </div>
    )}
    <div className="w-full md:w-auto lg:w-8/12 mt-8 mb-4 md:mb-0 md:ml-10 md:mt-0">
      <h4 className="text-center md:text-left py-4 md:pt-2 font-black">
        {title}
      </h4>
      <p className="text-lightgrey text-center md:text-left">{body}</p>
    </div>
  </div>
);

Card.propTypes = {
  body: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  img: PropTypes.shape({
    childImageSharp: PropTypes.shape({ fluid: PropTypes.shape() })
  }).isRequired
};

export default Card;
