import React from 'react';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

const NewsCard = ({ img, title, body, button, buttonText, linkTo }) => (
  <div className="w-full lg:w-5/6 bg-white p-4 rounded flex flex-col md:flex-row justify-center my-2 mx-auto">
    {img && (
      <div
        className="w-full h-full md:w-5/12 rounded"
        style={{
          background:
            'linear-gradient(180deg, #F5F6F9 0%, rgba(245, 246, 249, 0.0001) 100%)'
        }}
      >
        <Img
          alt={title}
          fluid={img.childImageSharp.fluid}
          className="flex rounded items-center justify-center m-auto"
          style={{
            minWidth: 240,
            width: '100%',
            maxHeight: '320px'
          }}
        />
      </div>
    )}
    <div className="flex flex-col w-full md:w-7/12 mt-8 mb-4 md:mb-0 md:ml-10 md:mt-0">
      <h4 className="text-start md:text-left py-4 md:pt-2 font-black">
        {title}
      </h4>
      <p className="text-lightgrey">{body}</p>
      {button && (
        <div className="w-full flex justify-start md:justify-end mt-4 mb-0 xl:mb-4 xl:mt-auto">
          <a href={linkTo} target="_blank" className="btn btn-primary">
            {buttonText}
          </a>
        </div>
      )}
    </div>
  </div>
);

NewsCard.propTypes = {
  body: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  img: PropTypes.shape({
    childImageSharp: PropTypes.shape({ fluid: PropTypes.shape() })
  }).isRequired,
  button: PropTypes.bool,
  buttonText: PropTypes.string
};

NewsCard.defaultProps = {
  button: false,
  buttonText: ''
};

export default NewsCard;
