/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Link from './Link';

function StyledLink(props) {
  const { type, activeStyle, to, title, children } = props;
  return (
    <Link
      to={to}
      title={title}
      type={type}
      activeStyle={activeStyle}
      className="py-2 text-lg text-bluegrey lg:text-base lg:px-5 hover:text-primary"
    >
      {children}
    </Link>
  );
}

function ExternalLink(props) {
  const { to, children } = props;
  return (
    <StyledLink type="external" activeStyle={{ color: '#0E1655' }} to={to}>
      {children}
    </StyledLink>
  );
}

const HomeNav = ({ show }) => {
  const navClass = clsx(
    'w-full h-full flex flex-col items-center justify-center lg:flex lg:w-2/3 lg:flex-row lg:justify-end',
    { hidden: !show }
  );

  return (
    <nav className={navClass}>
      <StyledLink to="intro">Bemutatkozás</StyledLink>
      <StyledLink to="where" title="Hol tanulhatom?">
        Hol tanulhatom?
      </StyledLink>
      <ExternalLink to="/folyamat">A képzés menete</ExternalLink>
      <ExternalLink to="partner">Partnerek ajánlatai</ExternalLink>
      <StyledLink to="faq" title="Gyakori kérdések">
        Gyakori kérdések
      </StyledLink>
      <StyledLink to="contact" title="Kapcsolat">
        Kapcsolat
      </StyledLink>
    </nav>
  );
};

HomeNav.propTypes = {
  show: PropTypes.bool.isRequired
};

export default HomeNav;
