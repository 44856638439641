import React from 'react';

function Icon() {
  return (
    <svg
      width="20"
      height="14"
      viewBox="0 0 20 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="highlight cursor-pointer"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20 10.5642V3.43582C20 3.43582 20 0 16.5534 0H3.44544C3.44544 0 0 0 0 3.43582V10.5642C0 10.5642 0 14 3.44544 14H16.5534C16.5534 14 20 14 20 10.5642M13.8829 7.00996L7.33537 10.8432V3.17558L13.8829 7.00996"
        fill="#0E1655"
      />
    </svg>
  );
}

export default Icon;
