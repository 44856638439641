import React, { useState } from 'react';
import Img from 'gatsby-image';
import { useStaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Facebook, Youtube, Mail } from '../svg';
import HamburgerMenu from './HamburgerMenu';
import Link from './Link';
import ProcessNav from './ProcessNav';
import HomeNav from './HomeNav';

const Header = ({ baseRoute }) => {
  const [show, setShow] = useState(false);
  const { logo } = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 657, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `);

  const headerClass = clsx('fixed w-full z-50 bg-white top-0', {
    'h-full': show
  });

  const topbarClass = clsx('flex-none bg-snow lg:block', {
    hidden: !show
  });

  return (
    <header className={headerClass}>
      <div className="flex flex-col h-full lg:flex-col-reverse">
        <div className="flex-grow flex flex-col container lg:flex-row lg:items-center h-full">
          <div className="w-full lg:w-1/3">
            <div className="flex flex-row">
              <Link className="w-10/12" to="/" type="external">
                <Img
                  fluid={logo.childImageSharp.fluid}
                  alt="HSUP"
                  style={{ maxWidth: 329 }}
                />
              </Link>
              <button
                type="button"
                onClick={() => setShow(!show)}
                className="w-2/12 outline-none lg:hidden"
              >
                <HamburgerMenu show={show} />
              </button>
            </div>
          </div>
          {baseRoute === '/' ? (
            <HomeNav show={show} />
          ) : (
            <ProcessNav show={show} />
          )}
        </div>
        <div className={topbarClass}>
          <div className="container lg:flex lg:justify-end lg:pr-5">
            <div className="flex flex-col lg:flex-row justify-end items-center p-3">
              <a
                href="/app"
                target="_blank"
                type="external"
                className="font-semibold text-secondary lg:mr-5 uppercase hover:text-primary
                 py-4 lg:py-0"
              >
                Bejelentkezés
              </a>
              <div className="flex justify-center items-center">
                <a
                  href="https://www.facebook.com/HungarianStartupUniversityProgram"
                  className="mr-5"
                  target="_blank"
                >
                  <Facebook />
                </a>
                <a href="mailto:info.hsup@xiagency.hu" className="mr-5">
                  <Mail />
                </a>
                <a
                  href="https://www.youtube.com/user/InnovaciosHivatal"
                  target="_blank"
                >
                  <Youtube />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

Header.propTypes = {
  baseRoute: PropTypes.string.isRequired
};

export default Header;
