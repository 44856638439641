import React from 'react';
import PropTypes from 'prop-types';

const FaqItem = ({
  handleClick,
  question,
  index,
  activeAnswerIndex,
  children
}) => {
  const isActive = activeAnswerIndex === index;
  return (
    <div className="mb-8">
      <div
        className="flex flex-row justify-between items-center border-b border-primary cursor-pointer focus:outline-none"
        onClick={handleClick}
        onKeyDown={handleClick}
        tabIndex={0}
        role="button"
      >
        <h4 className="text-lg my-1 text-primary font-bold">{question}</h4>
        <button type="button">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{
              transform: isActive ? 'rotate(0deg)' : 'rotate(180deg)'
            }}
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20ZM16 13L12 9L8 13H16Z"
              fill="#0E1655"
            />
          </svg>
        </button>
      </div>
      {isActive && <div>{children}</div>}
    </div>
  );
};

FaqItem.propTypes = {
  question: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  activeAnswerIndex: PropTypes.number.isRequired,
  handleClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired
};

export default FaqItem;
