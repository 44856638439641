import React from 'react';

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="8"
      height="16"
      fill="none"
      viewBox="0 0 7 14"
      className="highlight cursor-pointer"
    >
      <path
        fill="#0E1655"
        d="M4.928 4.427V3.395c0-.503.336-.62.571-.62h1.448V.562L4.953.554c-2.214 0-2.717 1.65-2.717 2.706v1.167H.956V7.01h1.291v6.454h2.582V7.01h1.916l.093-1.014.142-1.568H4.928z"
      />
    </svg>
  );
}

export default Icon;
