import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import Link from './Link';
import adatvedelem from '../../documents/adatvedelem.pdf';

const Footer = () => {
  const { logo, nkfih, xiagency } = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 657, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      nkfih: file(relativePath: { eq: "nkfih_logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 174, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      xiagency: file(relativePath: { eq: "xiagency_logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 174, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `);
  const content = [{ name: 'Adatkezelési tájékoztató', linkTo: adatvedelem }];
  return (
    <footer className="flex flex-col bg-primary">
      <div className="flex container items-center justify-between p-4">
        <Link className="w-3/4" to="/">
          <Img
            fluid={logo.childImageSharp.fluid}
            alt="HSUP"
            style={{ maxWidth: 329 }}
          />
        </Link>
        <a
          className="w-24"
          href=" https://xiagency.hu/web/guest/kezdolap"
          target="_blank"
        >
          <Img
            fluid={xiagency.childImageSharp.fluid}
            alt="xiagency"
            className="w-24"
            style={{ maxWidth: 329 }}
          />
        </a>
        <a className="w-24" href="https://nkfih.gov.hu/" target="_blank">
          <Img
            fluid={nkfih.childImageSharp.fluid}
            alt="HSUP"
            className="w-24"
            style={{ maxWidth: 329 }}
          />
        </a>
      </div>
      <div className="flex bg-secondary px-4">
        <div className="flex container align-center justify-center sm:justify-end flex-col sm:flex-row">
          {content.map(({ name, linkTo }) => (
            <a
              href={linkTo}
              key={linkTo}
              name={name}
              target="_blank"
              className="inline-block text-primary py-3 pl-6 hover:underline text-sm text-center"
            >
              {name}
            </a>
          ))}
          <Link
            type="external"
            to="/impresszum"
            className="inline-block text-primary py-3 pl-6 hover:underline text-sm text-center"
          >
            Impresszum
          </Link>
          <Link
            type="external"
            to="/#contact"
            className="inline-block text-primary py-3 pl-6 hover:underline text-sm text-center"
          >
            Ügyfélszolgálat
          </Link>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
