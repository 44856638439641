import React from 'react';

function Icon() {
  return (
    <svg
      width="15"
      height="14"
      viewBox="0 0 17 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="highlight cursor-pointer"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.4183 6.01782C7.0512 6.01782 0.0078125 2.0237 0.0078125 2.0237V1.4C0.0078125 0.627199 0.635011 0 1.40921 0H15.4274C16.2023 0 16.8295 0.627199 16.8295 1.4L16.8183 2.1C16.8183 2.1 9.8512 6.01782 8.4183 6.01782Z"
        fill="#0E1655"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.4183 7.94278C9.917 7.94278 16.8183 4.19995 16.8183 4.19995L16.8295 12.5999C16.8295 13.3727 16.2023 13.9999 15.4274 13.9999H1.40921C0.635712 13.9999 0.0078125 13.3727 0.0078125 12.5999L0.0190125 4.19995C0.0183125 4.19995 7.0512 7.94278 8.4183 7.94278Z"
        fill="#0E1655"
      />
    </svg>
  );
}

export default Icon;
